
export default {
    name: "ContactPerson",
    props: {
        business: {
            type: Object,
            required: true,
        },
        name: {
            type: String,
            default: null,
        },
        image: {
            type: String,
            default: null,
        },
    },
    computed: {
        contactPerson() {
            return this.business?.public_contact_person ?? {}
        },
        internalName() {
            return (
                this.name ??
                `${this.contactPerson.gender} ${this.contactPerson.firstname} ${this.contactPerson.lastname}`
            )
        },
        img() {
            return (
                this.image ??
                this.$getImage({
                    image: this.contactPerson.profile_picture,
                    preferedSize: "1",
                }).src
            )
        },
        imgAlt() {
            return `${this.name} Avatar`
        },
        subtitle() {
            return `Ansprechpartner bei ${this.business.name}`
        },
    },
}
